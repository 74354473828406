import React from 'react'
import { Link } from 'gatsby'
import './style.scss'

class Navi extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showWebSideMenu: false,
    }
  }
  componentDidMount() {
    if (window && typeof window !== `undefined`) {
    window.addEventListener('scroll', () => {
      document.getElementById('navbar').classList.add('js-is-sticky')
      if (window.scrollY === 0) {
        document.getElementById('navbar').classList.remove('js-is-sticky')
      }
    })
  }
  }

  toggleClass = event => {
    // this.setState({ showWebSideMenu: !this.state.showWebSideMenu })
    if (this.state.showWebSideMenu) {
      this.state.showWebSideMenu = false
    } else {
      this.state.showWebSideMenu = true
    }
    if (this.state.showWebSideMenu) {
      document.getElementById('sidebar-toggle-1').classList.add('web-side-menu')
    } else {
      document
        .getElementById('sidebar-toggle-1')
        .classList.remove('web-side-menu')
    }
  }
  toggleClass1 = event => {
    // this.setState({ showWebSideMenu: !this.state.showWebSideMenu })
    if (this.state.showWebSideMenu) {
      this.state.showWebSideMenu = false
    } else {
      this.state.showWebSideMenu = true
    }
    if (this.state.showWebSideMenu) {
      document.getElementById('sidebar-toggle-2').classList.add('web-side-menu')
    } else {
      document
        .getElementById('sidebar-toggle-2')
        .classList.remove('web-side-menu')
    }
  }
  render() {
    const { location } = this.props
    return (
      <div id="wrapper" className="wrapper">
        <div
          className="navbar header-navbar sticky-wrapper position-fixed w-100"
          id="navbar"
          role="banner"
          itemScope="itemscope"
        >
          <div className="navbar-header">
            <Link to="/" className="navbar-brand">
              <img
                src="/assets/img/limitless-logo.png"
                alt=""
                className="light-logo"
                width="210"
                height="100"
              />

              <img
                src="/assets/img/limitless-logo.png"
                alt=""
                className="sticky-logo"
                width="210"
                height="100"
              />
            </Link>
          </div>

          {/* Main header for web start */}
          <nav className="nav-container d-none d-lg-block" aria-hidden="true">
            <ul className="nav site-navigation" id="navbar-menu-1">
              <li className="menu-item current-menu-item">
                <Link to="/">Home</Link>
              </li>
              <li className="menu-item">
                <Link to="/services">Services</Link>
              </li>
              <li className="menu-item">
                <Link to="/health-analysis">Health Analysis</Link>
              </li>
              <li className="menu-item">
                <Link to="/faqs">F.A.Q</Link>
              </li>
              {/* <li className="menu-item">
                <Link to="/gallery-list">Gallery</Link>
              </li> */}
              <li className="menu-item">
                <Link to="/contact">Contact Us</Link>
              </li>
              <li className="menu-item">
                <Link to="/limitless-learning">Limitless Learning</Link>
              </li>
              {/* <li className="menu-item">
                <a
                  href="https://limitless-with-jess.myshopify.com/"
                  target="_blank"
                >
                  Store
                </a>
              </li> */}
            </ul>
          </nav>
          {/* Main header for web end */}

          {/* Main header for  tablet and mobiles  start */}

          <nav className="nav-container d-block d-lg-none" aria-hidden="true">
            <ul className="nav site-navigation" id="navbar-menu-2">
              <li
                className="menu-item menu-right-sidebar"
                id="sidebar-toggle-2"
                className="sidebar-toggle"
                onClick={this.toggleClass1}
              >
                <span>
                  <i className="feather-menu"></i>
                </span>
                <div id="right-sidebar-2" className="sidebar right-sidebar">
                  <div className="right-sidebar-body">
                    <div className="clearfix mb-0">
                      <button className="close">
                        <i className="feather-x"></i>
                      </button>
                    </div>
                    <ul className="menu">
                      <li className="menu-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/services">Services</Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/health-analysis">Health Analysis</Link>
                      </li>
                      {/* <li className="menu-item">
                        <Link to="/gallery-list">Gallery</Link>
                      </li> */}
                      <li className="menu-item">
                        <Link to="/faqs">F.A.Q</Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/contact">Contact Us</Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/limitless-learning">Limitless Learning</Link>
                      </li>
                      {/* <li className="menu-item">
                        <a
                          href="https://limitless-with-jess.myshopify.com/"
                          target="_blank"
                        >
                          Store
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </nav>

          {/* Main header for  tablet and mobiles  end */}

          <ul className="nav site-navigation site-navigation-extras d-none d-xl-inline-flex">
            {/* right sidebar start */}
            <li
              className="menu-item menu-right-sidebar"
              id="sidebar-toggle-1"
              onClick={this.toggleClass}
              className="sidebar-toggle"
            >
              <span>
                <i className="feather-menu"></i>
              </span>
              <div id="right-sidebar-1" className="sidebar right-sidebar">
                <div className="right-sidebar-body">
                  <div className="clearfix mb-0">
                    <button className="close">
                      <i className="feather-x"></i>
                    </button>
                  </div>
                  <div className="sideMenu-data">
                    <ul className="menu">
                      <li className="menu-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/services">Services</Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/health-analysis">Health Analysis</Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/faqs">F.A.Q</Link>
                      </li>
                      {/* <li className="menu-item">
                        <Link to="/gallery-list">Gallery</Link>
                      </li> */}
                      <li className="menu-item">
                        <Link to="/contact">Contact Us</Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/limitless-learning">Limitless Learning</Link>
                      </li>
                      {/* <li className="menu-item">
                        <a
                          href="https://limitless-with-jess.myshopify.com/"
                          target="_blank"
                        >
                          Store
                        </a>
                      </li> */}
                    </ul>
                    <div className="widget text-widget section-sub-heading-lines text-left d-block contact-details">
                      <img
                        src="/assets/img/limitless-logo.png"
                        alt=""
                        width="210"
                      />
                      <p className="title d-flex flex-column align-items-start">
                        <span className="mb-1">E: <span className="text-lowercase">limitlesswithjess@gmail.com</span></span>
                        P: (530) 306-9778
                        <br />
                        {/* A: 1467, Mulbery Street, Houston */}
                      </p>
                      <div className="widget text-widget fs-13">
                        <p>
                          &copy; 2020 LimitLess With Jess. All rights reserved.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <!-- /.widget --> */}
                  {/* <ul className="social-list social-list-colored-circle">
              <li>
                  <Link to="facebook.com" className="bg-facebook">
                    <i className="feather-facebook"></i>
                  </Link>
                </li>
                <li>
                  <Link to="instagram.com" className="bg-instagram">
                    <i className="feather-instagram"></i>
                  </Link>
                </li>
                <li>
                  <Link to="twitter.com" className="bg-twitter">
                    <i className="feather-twitter"></i>
                  </Link>
                </li>
                <li>
                  <Link to="youtube.com" className="bg-youtube">
                    <i className="feather-youtube"></i>
                  </Link>
                </li>
              </ul> */}
                  {/* <!-- /.social-list --> */}
                  {/* <figure className="mb-0">
                    <img src="/assets/demo/payment-options.jpg" alt="" />
                  </figure> */}
                </div>
              </div>
            </li>
            {/* right sidebar end */}
          </ul>
        </div>
      </div>
    )
  }
}

export default Navi
